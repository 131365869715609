import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { TipoUsuario } from './class-tipoUsuario';

// import 'rxjs/add/operator/map';

@Injectable()
export class TipoUsuarioService {
  static ENDPOINT: string = '/api/tipoUsuario/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }  

  public getAll(): Observable<any> {
    return this._http
               .get(TipoUsuarioService.ENDPOINT.replace(':id', ''));
  }

  public getByUsuarioId(UsuarioId: string): Observable<any> {
    return this._http
               .get(TipoUsuarioService.ENDPOINT.replace(':id', UsuarioId));
  }
}