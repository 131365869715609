import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './+guards';


const routes: Routes = [
  { path: '',  loadChildren: './+inicio/+carrusel/carrusel.module#CarruselModule' }, 
  { path: 'carrusel',  loadChildren: './+inicio/+carrusel/carrusel.module#CarruselModule'},
  { path: 'contacto',  loadChildren: './+inicio/+contacto/contacto.module#ContactoModule'},

  // modulo Seguridad
  { path: 'seguridad/iniciarSesion',  loadChildren: './+seguridad/+iniciarSesion/iniciarSesion.module#IniciarSesionModule'},
  { path: 'seguridad/usuarios', canActivate: [AuthGuard],  loadChildren: './+seguridad/+usuario/usuario.module#UsuarioModule'},
  { path: 'seguridad/roles', canActivate: [AuthGuard],  loadChildren: './+seguridad/+rol/rol.module#RolModule'},
  { path: 'seguridad/obtenerClaveProveedor', canActivate: [AuthGuard],   loadChildren: './+seguridad/+obtenerClaveProveedor/obtenerClaveProveedor.module#ObtenerClaveProveedorModule'},
  { path: 'seguridad/maestroCorreosSeguridad', canActivate: [AuthGuard],  loadChildren: './+seguridad/+maestroCorreoSeguridad/maestroCorreoSeguridad.module#MaestroCorreoSeguridadModule'},
  { path: 'seguridad/controlInicioSession', canActivate: [AuthGuard],  loadChildren: './+seguridad/+controlInicioSession/controlInicioSession.module#ControlInicioSessionModule'},
  { path: 'seguridad/consultaLogServicios', canActivate: [AuthGuard],  loadChildren: './+seguridad/+logError/logError.module#LogErrorModule'},
  
  
  { path: 'proveedores/bancos', canActivate: [AuthGuard],  loadChildren: './+proveedores/+banco/banco.module#BancoModule'},
  { path: 'proveedores/categorias',canActivate: [AuthGuard],   loadChildren: './+proveedores/+categoria/categoria.module#CategoriaModule'},
  { path: 'proveedores/metodopagos', canActivate: [AuthGuard],  loadChildren: './+proveedores/+metodoPago/metodoPago.module#MetodoPagoModule'},
  { path: 'proveedores/metodoentregas', canActivate: [AuthGuard],  loadChildren: './+proveedores/+metodoEntrega/metodoEntrega.module#MetodoEntregaModule'},
  { path: 'proveedores/requisitos',  canActivate: [AuthGuard], loadChildren: './+proveedores/+requisito/requisito.module#RequisitoModule'},
  { path: 'proveedores/paisesOficinas', canActivate: [AuthGuard],  loadChildren: './+proveedores/+paisesOficina/paisesOficina.module#PaisesOficinaModule'},
  { path: 'proveedores/proveedores', canActivate: [AuthGuard],  loadChildren: './+proveedores/+proveedor/proveedor.module#ProveedorModule'},
  { path: 'proveedores/recepcionfactura', canActivate: [AuthGuard],  loadChildren: './+proveedores/+recepcionFactura/recepcionFactura.module#RecepcionFacturaModule'},
  { path: 'proveedores/documentosentregados', canActivate: [AuthGuard],  loadChildren: './+proveedores/+documentosEntregados/documentosEntregados.module#DocumentosEntregadosModule'},
  { path: 'proveedores/estadoCuenta', canActivate: [AuthGuard],  loadChildren: './+proveedores/+estadoCuenta/estadoCuenta.module#EstadoCuentaModule'},
  { path: 'proveedores/notificacionPagos', canActivate: [AuthGuard],   loadChildren: './+proveedores/+notificacionPago/notificacionPago.module#NotificacionPagoModule'},
  { path: 'proveedores/reimpresionFacturaTicket', canActivate: [AuthGuard],  loadChildren: './+proveedores/+reimpresionFacturaTicket/reimpresionFacturaTicket.module#ReimpresionFacturaTicketModule'},
  { path: 'proveedores/recepcionfacturaAnular', canActivate: [AuthGuard],  loadChildren: './+proveedores/+recepcionFacturaAnular/recepcionFacturaAnular.module#RecepcionFacturaAnularModule'},
  { path: 'metricas/metricas', canActivate: [AuthGuard],  loadChildren: './+metricas/+metricas/metricas.module#MetricasModule'},

  { path: 'sla/incident', canActivate: [AuthGuard],  loadChildren:   './+sla/+incident#IncidentModule'},
  { path: 'sla/reporteSlaCategoria', canActivate: [AuthGuard],  loadChildren: './+sla/+reporteSlaCategoria#ReporteSlaCategoriaModule'},
  { path: 'sla/reporteSlaNivelesUsuarios', canActivate: [AuthGuard],  loadChildren: './+sla/+reporteSlaNivelesUsuarios#ReporteSlaNivelesUsuariosModule'},

  { path: 'clientes/maestroCorreo',canActivate: [AuthGuard],  loadChildren:   './+clientes/+maestroCorreo/maestroCorreo.module#MaestroCorreoModule'},
  { path: 'clientes/estadoCuenta', canActivate: [AuthGuard],   loadChildren:   './+clientes/+estadoCuenta/estadoCuenta.module#EstadoCuentaModule'},
  { path: 'clientes/reciboPago', canActivate: [AuthGuard],  loadChildren:   './+clientes/+reciboPago#ReciboPagoModule'},
  { path: 'clientes/generarClave', canActivate: [AuthGuard],  loadChildren:   './+clientes/+generarClave/generarClave.module#GenerarClaveModule'},
  { path: 'clientes/enviarCorreoCliente', canActivate: [AuthGuard],  loadChildren:   './+clientes/+enviarCorreoCliente/enviarCorreoCliente.module#EnviarCorreoClienteModule'},
  { path: 'clientes/conciliacionesCliente', canActivate: [AuthGuard],  loadChildren:   './+clientes/+conciliacionEstadoCuenta/conciliacionEstadoCuenta.module#ConciliacionEstadoCuentaModule'},
  { path: 'clientes/consultaConciliacionesCliente', canActivate: [AuthGuard],  loadChildren:   './+clientes/+consultaConciliaciones/consultaConciliaciones.module#ConsultaConciliacionesModule'},
  { path: 'clientes/cierreCaja', canActivate: [AuthGuard],  loadChildren:   './+clientes/+cierreCaja/cierreCaja.module#CierreCajaModule'},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
