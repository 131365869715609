import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  Observable
} from 'rxjs';
import { Usuario } from '../+usuario/class-usuario';
import { Pais } from '../../+proveedores/+pais/class-pais';
import { PaisesOficina } from '../../+proveedores/+paisesOficina/class-paisesOficina';

@Injectable({ providedIn: 'root' })
export class IniciarSesionService {
  static ENDPOINT: string = '/api/usuarios/:id';
    constructor(private _http: HttpClient) { }

    public iniciarSesion(entidad: Usuario, pais: Pais, oficina: PaisesOficina, tipoUsuario:string): Observable<any> {
      let headers = new HttpHeaders();
      return this._http
      .put<any>(IniciarSesionService.ENDPOINT.replace(':id', `iniciarsesion/${entidad.UsuarioID}/${pais.PaisID}/${oficina.OficinaID}/${tipoUsuario}`), entidad, {headers})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        
        if (user && user.token) {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let u = _.assignIn(user.entidadUsuario,pais,{ "Oficina": oficina});
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log(user)
            return user;
        }
        
      }));

    }
    public comprobarUsuario(entidad: Usuario, pais: string, oficina: string, tipoUsuario:string): Observable<any> {
      let headers = new HttpHeaders();
      return this._http
      .put<any>(IniciarSesionService.ENDPOINT.replace(':id', `iniciarsesion/${entidad.UsuarioID}/${pais}/${oficina}/${tipoUsuario}`), entidad, {headers})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        
        if (user && user.token) {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let u = _.assignIn(user.entidadUsuario,pais,{ "Oficina": oficina});
            localStorage.setItem('currentUser', JSON.stringify(user));
            
            return user;
        }
        
      }));

    }

    logout() {
        localStorage.removeItem('currentUser');
    }
}