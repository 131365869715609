'use strict';

export class TipoUsuario {
    public Id: number;
    public TipoUsuario: string;
    public Descripcion: string;
    public Icon: string;
    public ModificadoPor: string;
    public FechaModificado: Date;
    public CreadoPor: string;
    public FechaCreado: Date;
}
