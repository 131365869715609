import { Injectable } from '@angular/core'

import {Sesion} from '../+seguridad/+iniciarsesion/class-sesion';

import { CookieService } from 'ngx-cookie-service';
// import {Cookie} from 'ng2-cookies/ng2-cookies';


@Injectable()
export class GlobalSharedService{
 private _sesion : Sesion;
 private _tipoUsuario:string;
//  private c: CookieService;
 constructor( private c: CookieService ) { }

 get sesion(): Sesion {
   let s:any = this.c.get('sesion');   

    if(this._sesion == null && s != null){            
      s = atob(s);      

      this._sesion = s;            
    }

    return this._sesion;
  }

   get tipoUsuario():string
    {
        return this._tipoUsuario;
    }
    
set tipoUsuario(newVal:string)
{
  this._tipoUsuario= newVal;
}

  set sesion(newVal: Sesion) {
    this._sesion = newVal;        
  }

};
