import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import * as jwt from 'jsonwebtoken';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { RegistroSession } from './class-controlInicioSession';

@Injectable()
export class ControlInicioSessionService {
  static ENDPOINT: string = '/api/controlInicioSession/:id';
  

  constructor(@Inject(HttpClient) private _http: HttpClient) {
  }

  public getAll(): Observable<any> {
    return this._http
               .get(ControlInicioSessionService.ENDPOINT.replace(':id', ''));
  }

  
  public getById(id:string): Observable<any> {
    console.log(id)
    return this._http
               .get(ControlInicioSessionService.ENDPOINT.replace(':id', id));
  }

  public update(entidad: RegistroSession): Observable<any> {
    console.log(entidad)
    let _id: string = entidad.Id.toString();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this._http
               .put(ControlInicioSessionService.ENDPOINT.replace(':id', _id), entidad, {headers});
  }

  public getByFechaInicioFechaFin(paisId:string, oficinaId:string, fechaInicial: string, fechaFinal: string ): Observable<any> {
    return this._http
               .get(ControlInicioSessionService.ENDPOINT.replace(':id', `getByFecha/${paisId}/${oficinaId}/${fechaInicial}/${fechaFinal}`));
  }
  
  public getTotalVisitas(fechaInicial: Date, fechaFinal: Date ): Observable<any> {
    return this._http
               .get(ControlInicioSessionService.ENDPOINT.replace(':id', `getTotalVisitas/inicio/${fechaInicial}/${fechaFinal}`));
  }

  public add(entidad: RegistroSession): Observable<any> {
    let m = localStorage.getItem("currenUser");
    console.log(m)
    let headers = new HttpHeaders()

    headers.append('Content-Type', 'application/json');

    return this._http
               .post(ControlInicioSessionService.ENDPOINT.replace(':id', ''), entidad, {headers});
  }


}