
              
    

<div>
  <mat-toolbar color="primary" style="display:flex; background: linear-gradient(to right, #00459C, #00459C);" >        
        <span style="width: 10%;text-align: center">
            <img src="../assets/img/logo.png" style="width: 100%; height: 100%" alt="GTM Shared Service Center">
        </span> 
        
        <span style="width: 15px"></span>
        <button mat-button class="mat-button"  style="color: #FFFFFF" [routerLink]="['/carrusel']" > <b>{{'Inicio' |translate}}</b></button>   
        <button mat-button class="mat-button"  style="color: #FFFFFF" [routerLink]="['/contacto']" > <b>{{'Contactenos' |translate}}</b></button>     
         <a href="https://gtmholdings.cloud.invgate.net"  target="_blank"> <button mat-button style="color: #FFFFFF"><b>MAT CSC</b></button></a>            
         
        
        <div *ngIf="globalSharedService.sesion.SessionIniciada" style="display: flex; align-items: center;">
            <mat-icon color="primary" style="padding-right: 2px;">assistant_photo</mat-icon>
            <span style="padding-right: 21px;font-size: 15px;color: #FFFFFF">                
                {{globalSharedService.sesion.NombrePais}} - {{globalSharedService.sesion.Oficina.NombreOficina}}
            </span>  
            <mat-icon color="primary" style="padding-right: 2px;">account_circle</mat-icon>
            <span style="padding-right: 10px; font-size: 15px;color: #FFFFFF">
                {{globalSharedService.sesion.Nombre}}
            </span>
        </div>
        <span class="example-fill-remaining-space"></span>
        <span style="font-size: 12pt">{{'Lenguaje' |translate}} &nbsp; &nbsp;</span>
        
        <mat-form-field  style="width: 10%">
            <mat-select
                [(ngModel)]="lenguajeSeleccionado"
                (ngModelChange)="switchLanguage(lenguajeSeleccionado.Id)"
                style="font-size: 12pt"
                
                >
                <mat-option *ngFor="let le of lenguajes" [value]="le">
                <small> {{ le.Nombre }} </small>
                </mat-option> 
            </mat-select>
            
        </mat-form-field>
        &nbsp; &nbsp;&nbsp;
                
        <mat-button-toggle-group name="botones" *ngIf='!globalSharedService.sesion.SessionIniciada' >
            <mat-button-toggle *ngFor="let tu of tipoUsuarios" 
                [value]="tu.Descripcion" 
                style="font-size: 15px; color:white" 
                value="right"
                (click) = "getTipoUsuario(tu.TipoUsuario)" 
                [routerLink]="['/seguridad/iniciarSesion']"> 
                <mat-icon> {{tu.Icon}} </mat-icon>
                <span>{{tu.Descripcion | translate}}</span> 
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle style="font-size: 15px; color:white" *ngIf="globalSharedService.sesion.SessionIniciada" (click)="cerrarSesion()">{{'Cerrar Sesion' | translate}}</mat-button-toggle>

  </mat-toolbar>
  <mat-sidenav-container color="primary" style="height: 87vh;background:white">
        
    <mat-sidenav #sidenav mode="side" [opened]="globalSharedService.sesion.SessionIniciada"style="width: 250px;background:#00459C" >
        <mat-list dense>
        <mat-list >   
            <mat-list-item *ngFor="let op of getModulos()" >
                <mat-icon style="color:white">{{op.ModuloIcon}}</mat-icon>
                <span style="padding-left: 5px; color:white ;font-size: 14px">{{op.ModuloId | translate}}</span>
                <span class="example-fill-remaining-space"></span>
                    <button  mat-icon-button  [matMenuTriggerFor]="menu" style="color: white">
                            <mat-icon  >more_vert</mat-icon>
                    </button>
                   
                    <mat-menu #menu="matMenu"> 
                        <button mat-menu-item  
                                *ngFor="let o of getOpciones(op.ModuloId)"
                                [routerLink]="[o.Accion]">
                            <mat-icon>dialpad</mat-icon>
                            <span >{{o.Nombre | translate}}</span>
                        </button>                                
                    </mat-menu>
              
            </mat-list-item>
            <mat-divider></mat-divider>
            </mat-list >  
        </mat-list>  
        
            <mat-list dense style="padding-top: 90%;">
                <mat-list-item>
                    <div *ngIf="visibilidadContador=='mostrar'">
                        <table>
                            <tr>
                        <h3 matLine> <span style="padding-left: 5px; color:white ;font-size: 14px">{{'TituloContadorVisitas' | translate}} </span> </h3>
                        </tr>
                        <tr>
                        <div *ngIf="chartLabels.length > 0">
                            <canvas  id="myChart"  baseChart width="15px" height="15px"
                            [labels]="chartLabels"
                            [datasets]='chartData'
                            [chartType]='chartType'
                            [options]='chartOptions'
                            [colors]= 'chartColors'
                            [routerLink]="[controlSessionPath]"
                        ></canvas>  
                        </div>               
                        </tr>
                        </table>
                    </div>
                    </mat-list-item>
            </mat-list>
     
    </mat-sidenav>       
  
<div style="width: 100%; height: 90%; display: flex; justify-content: center">
    <div style="padding-top: 5px">
        <router-outlet>
        </router-outlet>
        
    </div>
   
   
</div> 
</mat-sidenav-container>  
<div style="width: 100%;  text-align: right"> 
    <a href="http://smartsolutions.hn/" style="font-size: 13px" >Powered by Smart Solutions &nbsp; </a>
</div> 
</div>

