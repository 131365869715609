import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IniciarSesionService } from '../+seguridad/+iniciarSesion/iniciarSesion.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private iniciarSesionService: IniciarSesionService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                console.log("error++")
                console.log(err)

                // auto logout if 401 response returned from api
                this.iniciarSesionService.logout();
                
                location.assign('/');
            }
            console.log("error+1")
            const error = err.error.message || err.statusText;
            console.log(error)
            return throwError(error);
        }))
    }
}