import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


registerLocaleData(localeEs, 'es-ES', localeEsExtra);


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PortalNavComponent } from './portal-nav/portal-nav.component';
import { MyMaterialModule } from './myMaterialModule.module';
import { LayoutModule } from '@angular/cdk/layout';
// import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCheckboxModule, MatButtonToggleModule,MatMenuModule} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppState, InternalStateType } from './app.service';
import { ControlInicioSessionService } from './+seguridad/+controlInicioSession/controlInicioSession.service';
import { GlobalSharedService} from "./shared/globals";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from'@angular/common/http'
import { CookieService } from 'ngx-cookie-service';
import { SesionService} from "./router/sesionservice-router";
import { TipoUsuarioService } from './+seguridad/+tipoUsuario/tipoUsuario.service';
import { ChartsModule } from 'ng2-charts/ng2-charts';

import { fakeBackendProvider } from './+helpers';
import { JwtInterceptor, ErrorInterceptor } from './+helpers';
// import { routing }        from './app.routing';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


// import { ENV_PROVIDERS } from '../environments/environment';

// Application wide providers
const APP_PROVIDERS = [
  // ...APP_RESOLVER_PROVIDERS,
  AppState,
  CookieService,
  SesionService,
  GlobalSharedService,
  ControlInicioSessionService,
  TranslateService,
  TipoUsuarioService,
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  // provider used to create fake backend
  // fakeBackendProvider
  // { provide: LOCALE_ID, useValue: 'es' }// ?? esto creo q puso en frances el proyecto.. revisar
  // ContactoService
];


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    PortalNavComponent
  ],
  imports: [
    MyMaterialModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
      }
      })
      

  ],

  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


 }
